<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="11">
                <b-card v-if="!isSales">
                    <b-form-group label="Pilih Sales">
                        <v-select :options="salesOptions" v-model="selectedSales" :reduce="option => option.value" label="text"></v-select>
                    </b-form-group>
                </b-card>
                <form-tanggal @submit="submit"></form-tanggal>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import {BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import FormTanggal from './components/FormTanggal'
import vSelect from 'vue-select'
export default {
    components: {
        FormTanggal,
        vSelect,
        BTable, BOverlay, BRow, BCol, BCard, BButton, BFormGroup, BFormInput
    },
    data: () => ({
        salesOptions: [],
        selectedSales: null,
        loading: false,
        form: {
            url: null,
            tgl_awal: null,
            tgl_akhir: null
        },
        fields: [
            { key: 'order', label: '#' },
            { key: 'tanggal', label: 'Tanggal' },
            { key: 'nomor', label: 'Nomor' },
            { key: 'akun', label: 'Akun' },
            { key: 'debit', label: 'Debit' },
            { key: 'kredit', label: 'Kredit' },
            { key: 'total', label: 'Total' }
        ]
    }),
    methods: {
        async submit({tgl_awal, tgl_akhir}) {
            if(!this.isSales && !this.selectedSales) {
                this.displayError({
                    message: 'Harap pilih sales'
                })
                return false
            }
            this.loading = true
            this.form.tgl_awal = tgl_awal
            this.form.tgl_akhir = tgl_akhir
            this.form.url = this.$route.meta.api
            if(this.isSales) this.form.sales_id = this.user.karyawan.id
            else this.form.sales_id = this.selectedSales

            try {
                this.loading = true
                const response = await this.$store.dispatch('laporan/getData', this.form)
                window.open(URL.createObjectURL(response))
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async getSales() {
            this.loading = true
            const levels = await this.$store.dispatch('level/getData', {
                search: 'sales'
            })
            const salesLevel = levels.find(level => this.getShortName(level.nama_level) == 'sales')
            const params = {}
            if(salesLevel) params.level_id = salesLevel.id

            const sales = await this.$store.dispatch('pengguna/getData', params)
            this.salesOptions = sales.map(item => ({
                value: item.karyawan.id,
                text: item.karyawan.nama_lengkap
            }))
            this.loading = false
        }
    },
    created() {
        if(!this.isSales) {
            this.getSales()
        }
    }
}
</script>